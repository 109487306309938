/*
 * @Description: 权限管理
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/api/system.js
 */
import request from "@/plugins/axios";

// 菜单管理相关接口
export const ApiMenuMan = {
  allPermissionListByName(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-permission/permissions/name",
      method: "get",
      params
    });
  },
  add(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-permission/add",
      method: "post",
      data: params
    });
  },
  update(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-permission/update",
      method: "post",
      data: params
    });
  },
  del(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-permission/delete",
      method: "post",
      data
    });
  }
};

// 用户管理相关接口
export const ApiUserMan = {
  // 用户管理列表
  list(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/page",
      method: "post",
      data
    });
  },
  // 新建系统账号
  add(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/add",
      method: "post",
      data
    });
  },
  // 编辑用户信息
  update(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/update",
      method: "post",
      data: params
    });
  },

  del(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-user/delete",
      method: "post",
      data: params
    });
  }
};

// 角色管理相关接口
export const ApiRoleMan = {
  // 角色下拉列表选项
  roleOptions(platform) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-role/role/list/1",
      method: "get",
      params: {
        platform
      }
    });
  },
  list(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-role/role/page",
      method: "post",
      data
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-role/role/add",
      method: "post",
      data
    });
  },
  edit(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-role/role/update",
      method: "post",
      data
    });
  },
  del(data) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-role/role/delete",
      method: "post",
      data
    });
  },
  detail(params) {
    return request({
      url: "/homeauto-center-oauth/auth/sys-role/role/complex/info",
      method: "get",
      params
    });
  },
  allPermissionList() {
    return request({
      url: "/homeauto-center-oauth/auth/sys-permission/all",
      method: "get"
    });
  }
};

export const ApiAppUser = {
  list(data) {
    return request({
      url: "/homeauto-center-oauth/auth/customer/web/page",
      method: "post",
      data
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-oauth/auth/customer/web/add",
      method: "post",
      data
    });
  },
  edit(data) {
    return request({
      url: "/homeauto-center-oauth/auth/customer/web/update",
      method: "post",
      data
    });
  },
  del(data) {
    return request({
      url: "/homeauto-center-oauth/auth/customer/web/delete",
      method: "post",
      data
    });
  },
  detail(id) {
    return request({
      url: "/homeauto-center-oauth/auth/customer/web/userinfo",
      method: "get",
      params: { userId: id }
    });
  },
  familyList(id) {
    return request({
      url: `/homeauto-center-device/device/web/family/list/user/${id}`,
      method: "post"
    });
  }
};

// 数据字典
export const ApiDic = {
  list(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/list",
      method: "post",
      data
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/add",
      method: "post",
      data
    });
  },
  del(dicId) {
    return request({
      url: `/homeauto-center-device/device/web/dic/delete/${dicId}`,
      method: "delete"
    });
  },
  disable(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/disable",
      method: "post",
      data
    });
  },
  enable(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/enable",
      method: "post",
      data
    });
  },
  edit(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/edit",
      method: "post",
      data
    });
  }
};

export const ApiDicTag = {
  // 查询字典标签列表
  list(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/tag/list",
      method: "post",
      data
    });
  },
  // 新增字典标签
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/tag/add",
      method: "post",
      data
    });
  },

  // 修改字典标签
  edit(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/tag/edit",
      method: "post",
      data
    });
  },
  // 删除字典标签
  del(dicTagId) {
    return request({
      url: `/homeauto-center-device/device/web/dic/tag/delete/${dicTagId}`,
      method: "delete"
    });
  },

  // 停用字典标签
  disable(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/tag/disable",
      method: "post",
      data
    });
  },
  // 启用字典标签
  enable(data) {
    return request({
      url: "/homeauto-center-device/device/web/dic/tag/enable",
      method: "post",
      data
    });
  }
};
