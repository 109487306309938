<template>
  <form-dialog
    class="bind-project-dialog"
    title="项目选择"
    labelWidth="70px"
    width="640px"
    :formItems="formItems"
    :formData="formData"
    :formRule="formRule"
    @close="$emit('close')"
    @confirm="confirm"
  >
    <template v-slot:account>{{ detail.email }}</template>
    <template v-slot:bindIds>
      <div class="project-list">
        <div class="project-list-all">
          <div class="project-list-all-search">
            <el-input
              v-model="buildingName"
              placeholder="请输入楼盘名称"
              size="small"
              @blur="initProjectList"
              @keyup.enter.native="initProjectList"
            >
              <i slot="suffix" class="el-input__icon el-icon-search" @click="initProjectList"></i>
            </el-input>
          </div>
          <div class="project-list-all-tree">
            <div class="project-list-all-tree-scrollbar">
              <el-tree
                ref="tree"
                node-key="value"
                show-checkbox
                highlight-current
                :data="projectTree"
                :default-checked-keys="formData.bindIds"
                @check="checkProject"
              >
              </el-tree>
            </div>
          </div>
        </div>
        <div class="project-list-checked">
          <div class="project-list-checked-title">已选择</div>
          <div class="project-list-checked-value">
            <div class="project-list-checked-item" v-for="item in checkedProjectList" :key="item.value">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </form-dialog>
</template>

<script>
import { ApiBuilding } from "@/api/building";
import { deepClone } from "@/utils/util";

export default {
  name: "BindProjectDialog",
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      formItems: [
        {
          label: "系统账号",
          prop: "account",
          slot: true
        },
        {
          label: "项目",
          prop: "bindIds",
          slot: true
        }
      ],
      formData: {
        userId: this.detail.id,
        bindIds: []
      },
      formRule: {
        bindIds: [{ required: true, message: "请选择", trigger: "check" }]
      },
      buildingName: "",
      projectTree: [],
      allProjectTree: []
    };
  },
  computed: {
    checkedProjectList() {
      let list = [];
      this.allProjectTree.forEach((item) => {
        item.children.forEach((childItem) => {
          if (this.formData.bindIds.includes(childItem.value)) {
            list.push(childItem);
          }
        });
      });
      return list;
    }
  },
  mounted() {
    this.initProjectList();
    this.initCheckedProjectData();
  },
  methods: {
    // 初始化全部项目列表
    async initProjectList() {
      let params = {
        name: this.buildingName
      };
      try {
        const res = await ApiBuilding.list(params);
        this.projectTree = res;
        !this.allProjectTree.length && (this.allProjectTree = res);
      } catch (error) {
        console.log(error);
      }
    },
    // 初始化已绑定项目
    async initCheckedProjectData() {
      if (!this.detail.id) return;
      try {
        const res = await ApiBuilding.checkedProject(this.detail.id);
        this.formData.bindIds = res;
      } catch (error) {
        console.log(error);
      }
    },
    // 选择项目
    checkProject() {
      const checkedList = this.$refs.tree.getCheckedKeys(true);
      let list = deepClone(this.formData.bindIds);
      let projectList = [];
      this.projectTree.forEach((item) => {
        projectList.push(...item.children);
      });
      projectList = projectList.map((item) => item.value);
      if (this.projectTree.length === this.allProjectTree.length) {
        list = checkedList;
      } else {
        list = Array.from(new Set([...list.filter((item) => !projectList.includes(item)), ...checkedList]));
      }
      this.formData.bindIds = list;
    },
    async confirm() {
      try {
        await ApiBuilding.bindProject(this.formData);
        this.$message.success("绑定成功");
        this.$emit("close");
        this.$emit("bind-success");
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bind-project-dialog {
  .project-list {
    display: flex;
    width: 420px;
    &-all {
      flex: 1;
      width: 200px;
      margin-right: 20px;
      &-search {
        ::v-deep .el-input {
          width: 200px;
        }
      }
      &-tree {
        border: 1px solid #dcdfe6;
        &-scrollbar {
          height: 300px;
          overflow: auto;
        }
      }
    }
    &-checked {
      flex: 1;
      width: 200px;
      border: 1px solid #dcdfe6;
      &-title {
        padding-left: 10px;
        border-bottom: 1px solid #dcdfe6;
      }
      &-value {
        height: 300px;
        overflow: auto;
      }
      &-item {
        width: 178px;
        line-height: 1;
        margin: 10px auto;
      }
    }
  }
}
</style>
