/*
 * @Description:
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/api/address.js
 */

import request from "@/plugins/axios";

export function addressList() {
  return request({
    url: "/homeauto-center-device/device/web/project/get/projects",
    method: "get"
  });
}

// 获取省市区
export function pcaList() {
  return request({
    url: "/homeauto-center-device/device/web/area/cascadeList",
    method: "get"
  });
}
