<!--
 * @Description: 
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/views/system/systemMan/UserMan.vue
-->
<template>
  <div>
    <base-list-page
      :searchModel="searchModel"
      :tableModel="tableModel"
      :paginationModel.sync="paginationModel"
      @change="refreshTableData"
      @delItem="delItem"
      @handleBtn="handleBtn"
      addBtn="添加"
      @addItem="addUser"
    >
    </base-list-page>
    <dialog-form ref="dialogForm" :model="dialogModel" @handleSure="handleDialogSure">
      <template v-slot:roleId>
        <el-form-item label="角色" prop="roleId">
          <el-select
            v-model="dialogModel.items[5].value"
            multiple
            collapse-tags
            placeholder="请选择"
            no-data-text="请先选择所属平台"
          >
            <el-option v-for="item in roleOptions" :key="item.id" :label="item.roleName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
      </template>
    </dialog-form>
    <bind-project-dialog
      v-if="bindProjectDialogVisible"
      :detail="currentData"
      @close="bindProjectDialogVisible = false"
      @bind-success="refreshTableData"
    ></bind-project-dialog>
  </div>
</template>

<script>
import { ApiUserMan, ApiRoleMan } from "@/api/system";
import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { searchFormProperty, searchFormItemProperty } from "@/components/form/searchFormProperty";
import { VariableType } from "@/components/baseInput/baseInputProperty";
import { baseTableProperty, colProperty, btnProperty, colType } from "@/components/baseTable/baseTableProperty";
import { SelectOptions } from "@/components/baseInput/options";
import { dialogFormProperty, dialogFormItemProperty } from "@/components/form/dialogFormProperty";
import BindProjectDialog from "./components/BindProjectDialog";

export default {
  name: "UserMan",
  components: {
    BindProjectDialog
  },
  data() {
    const REG = /^1[3456789]\d{9}$/;
    let validateTel = (rule, value, callback) => {
      if (!REG.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      }
      callback();
    };
    return {
      searchModel: null,
      tableModel: null,
      dialogModel: null,
      accountStatusOptions: [
        { label: "启用", value: 1 },
        { label: "禁用", value: 0 }
      ],
      paginationModel: new basePaginationProperty(),
      rules: {
        platform: [{ required: true, message: "请选择所属平台", trigger: "change" }],
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateTel, trigger: "blur" }
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur", "change"] }
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }]
      },
      roleOptions: null,
      bindProjectDialogVisible: false,
      currentData: null
    };
  },
  mounted() {
    this.initSearch();
    this.initSearchRoleOptions();
    this.initDialogForm();
    this.initTableList();
    this.initRoleOptions();
  },
  methods: {
    initSearch() {
      let searchModel = searchFormProperty.Init();
      let items = [
        searchFormItemProperty.Init("系统账号", "email"),
        searchFormItemProperty.Init("姓名", "name"),
        searchFormItemProperty.Init("用户角色", "roleId", VariableType.SELECT),
        searchFormItemProperty.Init("账号状态", "status", VariableType.SELECT, ""),
        searchFormItemProperty.Init("创建时间", "createTimeFormat", VariableType.DATETIMERANGE)
      ];
      searchModel.setItems(items);
      searchModel.setUniqueWidth("createTimeFormat", "400px");
      searchModel.setOptions("status", this.accountStatusOptions);
      searchModel.setClearable(["roleId", "status"]);
      this.searchModel = searchModel;
    },
    initTableList() {
      let model = new baseTableProperty();
      model.treeProps = { children: "children", hasChildren: "hasChildren" };
      model.colProperty = [
        colProperty.Init("系统账号", "email", "300px"),
        colProperty.Init("姓名", "name"),
        colProperty.Init("用户角色", "roleName"),
        colProperty
          .Init("账号状态", "status", undefined, false)
          .setAddition(colType.KEY_MAP, this.accountStatusOptions),
        colProperty.Init("账号状态", "statusLabel"),
        colProperty.Init("创建时间", "createTimeFormat", "200px")
      ];
      model.opBtns = [btnProperty.Init("添加到项目"), btnProperty.Init("编辑"), btnProperty.Init("删除")];
      this.tableModel = model;
      this.refreshTableData();
    },
    initDialogForm() {
      let dialogModel = dialogFormProperty.Init();
      let items = [
        dialogFormItemProperty.Init("id", "id").setHidden(),
        dialogFormItemProperty.Init("系统账号", "email"),
        dialogFormItemProperty.Init("手机号", "mobile"),
        dialogFormItemProperty.Init("姓名", "name"),
        dialogFormItemProperty.Init("账号状态", "status", VariableType.SWITCH, "1"),
        dialogFormItemProperty.Init("角色", "roleId").setHidden()
      ];
      dialogModel.setItems(items);
      dialogModel.rules = this.rules;
      this.dialogModel = dialogModel;
    },
    // 初始化搜索框的角色下拉列表
    initSearchRoleOptions() {
      ApiRoleMan.roleOptions(this.$platform).then((res) => {
        let options = SelectOptions.Format(res, "roleName", "id");
        this.searchModel.setOptions("roleId", options);
      });
    },
    // 初始化角色下拉列表
    initRoleOptions() {
      if (!this.$platform) {
        this.roleOptions = [];
        return;
      }
      ApiRoleMan.roleOptions(this.$platform).then((res) => {
        this.roleOptions = res;
      });
    },
    refreshTableData() {
      let m = {
        platform: this.$platform,
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        ...this.searchModel.getItemsValue()
      };
      ApiUserMan.list(m).then((res) => {
        res.list.forEach((item) => {
          item.roleName = item.roles.map((childItem) => childItem.name).join(",");
          item.roleId = item.roles.map((childItem) => childItem.id);
        });
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    },
    handleDialogSure() {
      this.$store.commit("OPEN_FULL_SCREEN_LOADING");
      let m = {
        ...this.dialogModel.getItemsValue(),
        platform: this.$platform
      };
      let func = m.id ? "update" : "add";
      ApiUserMan[func](m)
        .then(() => {
          this.$message.success(`${m.id ? "编辑" : "编辑"}成功`);
          this.$refs.dialogForm.handleClose();
          this.refreshTableData();
        })
        .finally(() => {
          this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
        });
    },
    handleBtn({ btn, data }) {
      switch (btn.label) {
        case "编辑":
          this.editItem(data);
          break;
        case "添加到项目":
          this.bindProject(data);
          break;
      }
    },
    addUser() {
      this.dialogModel.visible = true;
      this.dialogModel.title = "添加用户信息";
      this.initRoleOptions();
    },
    delItem(id) {
      this.$store.commit("OPEN_FULL_SCREEN_LOADING");

      ApiUserMan.del([id])
        .then(() => {
          this.$message.success("删除成功");
          this.refreshTableData();
        })
        .finally(() => {
          this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
        });
    },
    editItem(data) {
      this.dialogModel.title = "编辑用户信息";
      this.dialogModel.visible = true;
      this.$nextTick(() => {
        this.dialogModel.setItemsValue({ ...data });
        this.initRoleOptions();
      });
    },
    // 添加到项目
    bindProject(data) {
      this.currentData = data;
      this.bindProjectDialogVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__wrapper .el-form-item__content {
  width: $input-width;
}
::v-deep .el-dialog .el-input {
  width: 232px;
}
</style>
