/*
 * @Description: 格式化选择
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/components/baseInput/options.js
 */
export class SelectOption {
  constructor(label, value) {
    this.label = label;
    if (value) {
      this.value = value;
    }
  }

  label = "";
  value = "";
}

export class SelectOptions {
  static Format(a, labelName, valueName) {
    return a.map(e => {
      return new SelectOption(e[labelName], e[valueName]);
    });
  }

  static Antd_Format(a, labelName, valueName) {
    return a.map(e => {
      return {
        title: e[labelName],
        value: e[valueName],
        key: e[valueName]
      };
    });
  }
}
