<!--
 * @Description: 角色管理
 * @Author: hongyan.yang
 * @LastEditors: hongyan.yang
 * @FilePath: /household/src/views/system/systemMan/RoleMan.vue
-->
<template>
  <div>
    <base-list-page
      :searchModel="searchModel"
      :tableModel="tableModel"
      :paginationModel.sync="paginationModel"
      @change="refreshTableData"
      @delItem="delItem"
      @handleBtn="handleBtn"
      addBtn="添加"
      @addItem="addRole"
    >
    </base-list-page>

    <dialog-form ref="dialogForm" :model="dialogModel" @handleSure="handleDialogSure" @handleClose="handleClose">
      <template v-slot:scopPaths>
        <el-form-item label="权限范围" prop="scopPaths">
          <a-tree-select
            style="width: 232px"
            :treeData="treeSelectData"
            :value="dialogModel.items[2].value"
            @change="dialogModel.items[2].value = $event"
            treeCheckable
            :treeDefaultExpandedKeys="value"
            :showCheckedStrategy="SHOW_PARENT"
            placeholder="请选择权限范围"
          />
        </el-form-item>
      </template>
      <template v-slot:sysPermissionIds>
        <el-form-item label="菜单权限" prop="sysPermissionIds">
          <el-tree
            ref="menuTree"
            show-checkbox
            :props="{ label: 'permissionName' }"
            node-key="id"
            :default-checked-keys="formatCheckedKeys"
            :data="currentMenuTree"
            @check="checkMenu"
          >
          </el-tree>
        </el-form-item>
      </template>
    </dialog-form>
  </div>
</template>

<script>
import { ApiRoleMan, ApiMenuMan } from "@/api/system";
import { addressList } from "@/api/address";

import { basePaginationProperty } from "@/components/basePagination/basePaginationProperty";
import { searchFormProperty, searchFormItemProperty } from "@/components/form/searchFormProperty";
import { VariableType } from "@/components/baseInput/baseInputProperty";
import { baseTableProperty, colProperty, btnProperty } from "@/components/baseTable/baseTableProperty";
import { dialogFormProperty, dialogFormItemProperty } from "@/components/form/dialogFormProperty";
import Vue from "vue";
import { treeToArray } from "@/utils/util";
import ATreeSelect from "ant-design-vue/lib/tree-select";
Vue.use(ATreeSelect);

const SHOW_PARENT = ATreeSelect.SHOW_PARENT;
export default {
  name: "RoleMan",
  data() {
    return {
      searchModel: null,
      tableModel: null,
      dialogModel: null,
      accountStatusOptions: [
        { label: "全部", value: -1 },
        {
          label: "启用",
          value: 1
        },
        {
          label: "禁用",
          value: 0
        }
      ],
      paginationModel: new basePaginationProperty(),
      rules: {
        platform: [{ required: true, message: "请选择所属平台", trigger: "change" }],
        roleName: [{ required: true, message: "请输入角色名称", trigger: "blur" }]
      },

      value: [],
      treeSelectData: null,
      SHOW_PARENT,
      // 当前系统的菜单权限列表
      currentMenuTree: [],
      currentMenuList: [],
      checkedKeys: []
    };
  },
  computed: {
    formatCheckedKeys() {
      let list = [];
      this.checkedKeys.forEach((item) => {
        this.currentMenuList.forEach((childItem) => {
          if (item === childItem.id && (!childItem.children || !childItem.children.length)) {
            list.push(item);
          }
        });
      });
      return list;
    }
  },
  mounted() {
    this.initSearch();
    this.initTableList();
    this.initDialogForm();
    this.initMenuList();
    addressList().then((res) => {
      this.treeSelectData = this.formatTreeData("", res);
    });
  },
  methods: {
    // 初始化菜单tree
    async initMenuList() {
      if (!this.$platform) {
        this.currentMenuTree = [];
        this.checkedKeys = [];
        return;
      }
      const params = {
        platform: this.$platform
      };
      try {
        const res = await ApiMenuMan.allPermissionListByName(params);
        this.currentMenuTree = res;
        treeToArray(this.currentMenuTree, this.currentMenuList);
      } catch (error) {
        console.log(error);
      }
    },
    formatTreeData(parentValue, arr) {
      return arr.map((e) => {
        let v = parentValue ? parentValue + "/" + e.value : e.value;
        let obj = {
          title: e.label,
          value: v,
          key: v
        };
        if (e.children && e.children.length) {
          obj.children = this.formatTreeData(v, e.children);
        }
        return obj;
      });
    },
    initSearch() {
      let searchModel = searchFormProperty.Init();
      let items = [
        searchFormItemProperty.Init("角色名称", "roleName"),
        searchFormItemProperty.Init("角色状态", "status", VariableType.SELECT, ""),
        searchFormItemProperty.Init("创建时间", "createTime", VariableType.DATETIMERANGE)
      ];
      searchModel.setItems(items);
      searchModel.items[2].width = "400px";
      this.searchModel = searchModel;
      this.$nextTick(() => {
        this.searchModel.setOptions("status", this.accountStatusOptions);
      });
    },
    initTableList() {
      let model = new baseTableProperty();
      model.treeProps = { children: "children", hasChildren: "hasChildren" };
      model.colProperty = [
        colProperty.Init("角色名称", "roleName"),
        colProperty.Init("账号状态", "statusName"),
        colProperty.Init("创建时间", "createTime", "230px")
      ];
      model.opBtns = [btnProperty.Init("编辑"), btnProperty.Init("删除")];
      this.tableModel = model;
      this.refreshTableData();
    },
    initDialogForm() {
      this.dialogModel = dialogFormProperty.Init();
      let items = [
        dialogFormItemProperty.Init("id", "id").setHidden(),
        dialogFormItemProperty.Init("角色名称", "roleName"),
        dialogFormItemProperty.Init("权限范围", "scopPaths").setHidden(),
        dialogFormItemProperty.Init("账号状态", "status", VariableType.SWITCH, "1"),
        dialogFormItemProperty.Init("角色", "sysPermissionIds").setHidden()
      ];
      this.dialogModel.rules = this.rules;
      this.dialogModel.setItems(items);
    },
    refreshTableData() {
      let m = {
        platform: this.$platform,
        pageNum: this.paginationModel.currentPage,
        pageSize: this.paginationModel.pageSize,
        ...this.searchModel.getItemsValue()
      };
      ApiRoleMan.list(m).then((res) => {
        this.tableModel.setData(res.list);
        this.paginationModel.total = res.total;
      });
    },
    handleClose() {
      this.checkedKeys = [];
    },
    handleBtn({ btn, data }) {
      switch (btn.label) {
        case "编辑":
          this.editItem(data);
          break;
      }
    },
    handleDialogSure() {
      this.$store.commit("OPEN_FULL_SCREEN_LOADING");
      const { id, status, roleName, scopPaths } = this.dialogModel.getItemsValue();
      let m = {
        sysRole: {
          id,
          status,
          roleName,
          platform: this.$platform
        },
        scopPaths: scopPaths,
        sysPermissionIds: this.checkedKeys
      };
      let func = id ? "edit" : "add";
      ApiRoleMan[func](m)
        .then(() => {
          this.$message.success(`${id ? "编辑" : "编辑"}成功`);
          this.$refs.dialogForm.handleClose();
          this.refreshTableData();
        })
        .finally(() => {
          this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
        });
    },
    addRole() {
      this.dialogModel.visible = true;
      this.dialogModel.title = "添加角色信息";
      this.dialogModel.setItemsValue({ status: 1 });
      this.initMenuList();
    },
    delItem(id) {
      this.$store.commit("OPEN_FULL_SCREEN_LOADING");
      ApiRoleMan.del([id])
        .then(() => {
          this.$message.success("删除成功");
          this.refreshTableData();
        })
        .finally(() => {
          this.$store.commit("CLOSE_FULL_SCREEN_LOADING");
        });
    },
    editItem(data) {
      ApiRoleMan.detail({ roleId: data.id }).then((res) => {
        this.dialogModel.title = "编辑角色信息";
        this.dialogModel.visible = true;
        const { status, roleName, platform } = res.sysRole;
        this.$nextTick(() => {
          let ruleForm = {
            id: data.id,
            status,
            roleName,
            platform,
            scopPaths: res.scopPaths
          };
          this.checkedKeys = res.sysPermissionIds;
          this.dialogModel.setItemsValue(ruleForm);
          this.initMenuList();
        });
      });
    },
    checkMenu() {
      const list = this.$refs.menuTree.getCheckedNodes(false, true);
      this.checkedKeys = [...list.map((item) => item.id)];
    }
  }
};
</script>

<style lang="scss" scoped>
.no-menu-data-tip {
  color: #aaa;
}
::v-deep .ant-select-selection {
  min-height: 36px;
}
::v-deep .ant-select-selection__rendered {
  line-height: 36px;
}
::v-deep .ant-select-selection__choice {
  height: 28px;
  line-height: 28px;
}

::v-deep .el-dialog .el-input {
  width: 232px;
}
</style>
